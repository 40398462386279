import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import { Container } from '../components/global/customers/styles';
import { Title, SubSection, CTAWrapper } from '../components/bot-builder-page/styles'
import WrapperOne from '../components/global/wrappers/WrapperOne';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';

const ComparisonHomepage = ({ data }) => {
	const {
		heroTitle,
		heroSubtitle,
		cta,
		seo
	} = data.contentfulPageComparisonHomepage;
	const vsPages = data.allContentfulPageAlternativePageV2.nodes;
	return (
		<>
			<Layout>
				<SEO
					{...seo}
				/>
				<Container>
					<Title className="font-black-54">{heroTitle}</Title>
					<SubSection>
						<p>{heroSubtitle.heroSubtitle}</p>
					</SubSection>
				</Container>
				<WrapperOne>
					<PostHolder data={vsPages} isComparisonPosts={true}></PostHolder>
				</WrapperOne>
				<CTAWrapper>
					<Container>
						<MasterCTA cta={cta} />
					</Container>
				</CTAWrapper>
			</Layout>
		</>
	);
};

export const query = graphql`
query($slug: String) {
	contentfulPageComparisonHomepage(slug: { eq: $slug }) {
		heroTitle
		heroSubtitle {
		  heroSubtitle
		}
		cta {
			...cta
		}
		seo {
			...seo
		}
	}
	allContentfulPageAlternativePageV2 {
		nodes {
			coverImage: comparisonThumbnail{
			...asset
		  }
		  title: comparisonName
		  slug
		}
	  }
}
`;

export default ComparisonHomepage;
