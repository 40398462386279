import styled, { css } from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontSize,
	setFontWeight,
} from '../../../utils/helpers';

const headerStyle = css`
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	color: ${setColors.lightBlack};
	font-weight: ${setFontWeight.extraBold} !important;
	line-height: 1.11 !important;
	max-width: 800px;
	letter-spacing: -1.5px !important;
	margin-bottom: 30px;
	padding: 70px 15px 0 15px;
	${mediaQuery.mobileXL`padding-top: 35px`}
`;

export const Title = styled.h1`
	${headerStyle}
`;

export const SectionTitle = styled.h2`
	${headerStyle}
`;

export const SubSection = styled.div`
	max-width: 900px;
	padding: 0 15px;
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	p {
		margin: 0 0 0.85em;
		line-height: 1.3 !important;
		font-weight: ${setFontWeight.normal} !important;
		font-size: ${setFontSize.verySmall};
		${mediaQuery.laptopXL`font-size : ${setFontSize.mediumTiny}`};
	}
	div {
		padding: 30px 0;
	}
	${mediaQuery.mobileXL`margin-bottom : 20px`}
`;

export const ImageWrapper = styled.div`
	width: 100%;
	${({ theme }) => theme.marginAuto};
	padding: 30px 0;
	box-sizing: border-box;
	${mediaQuery.mobileXL`display: ${props => props.hide && 'none'}`}
`;

export const SnippetWrapper = styled.div`
	padding-top: 70px;
	${mediaQuery.mobileXL`padding-top: 5px`}
`;

export const CustomerStoryWrapper = styled.div`
	max-width: 1130px;
	margin: 0px auto;
	padding: 100px 0px;
	${mediaQuery.laptopXL`padding-top: 190px;`}
	${mediaQuery.mobileXL`padding-top: 113px;padding-bottom: 55px;`}
`;

export const CustomerStoryWrapper2 = styled.div`
	@media (max-width: 900px) and (min-width: 767px) {
		#customer-img-wrapper {
			.gatsby-image-wrapper {
				min-width: 431px;
			}
		}
	}

	padding-top: 157px;
	${mediaQuery.mobileXL`padding-top: 67px;`}
`;

export const SnippetContainer = styled.div`
	#feature-section {
		padding-top: 0;
		margin-top: 80px;
		${mediaQuery.mobileXL`margin-top: -40px;`};
	}
`;

export const SectionWrapper = styled.div`
	padding-top: 68px;
	${mediaQuery.mobileXL`padding-top: 5px; margin-bottom: -40px;`}
	margin-bottom: 11px;
`;

export const ChannelTitle = styled.div`
	max-width: 800px;
	margin: 0 auto;
`;

export const BranchingWrapper = styled.div`
	#feature-section {
		padding-top: 150px;
		${mediaQuery.laptopXL`margin-top: 5px;`};
		${mediaQuery.mobileXL`padding-top: 90px;`};
	}
`;

export const OmiChannelWrapper = styled.div`
	padding-top: 75px;
	${mediaQuery.mobileXL`padding-top: 2px;`};
`;

export const CTAWrapper = styled.div`
	padding-top: 120px;
	${mediaQuery.mobileXL`padding-top: 54px;padding-bottom: 20px;`};
`;

export const TextBoxWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileXL`padding-top: 54px;padding-bottom: 20px;`};
`;

export const UsecaseWrapper = styled.div`
	padding: 40px 0px 65px;
	.sectionLink {
		display: flex;
		justify-content: center;
		padding-top: 30px;
		a {
			color: ${setColors.themeBlue};
			font-size: ${setFontSize.veryVerySmall};
			text-decoration: none;
		}
	}
	section {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
`;
export const ToolkitWrapper = styled.div`
	padding: 40px 0px;
	background-color: ${setColors.lightestBlue};
`;
